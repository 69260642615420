<template>
  <div class="container app-content pt-4">
    <p>RolesShow</p>
  </div>
</template>

<script>
export default {
  name: 'RolesShow',
};
</script>

<style scoped lang="scss"></style>
